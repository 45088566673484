<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
    >
      <div class="d-block mb-4 mb-md-0">
        <h2 class="h4">{{ $t("saleParticipation.report") }}</h2>
        <p class="mb-0">{{ reportName }}</p>
      </div>
    </div>
    <div class="card card-body border-0 shadow mb-4">
      <form id="filterReport" @submit.prevent="getReport(formData)">
        <div
          class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
        >
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="location">{{
                $t("saleParticipation.location")
              }}</label>
              <select
                name="location"
                id="location"
                class="form-select"
                v-model="formData.location"
                required
              >
                <option
                  v-for="location in locations.data"
                  :key="location.code"
                  :value="location.code"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="form-group">
              <label for="category">{{
                $t("saleParticipation.category")
              }}</label>
              <select
                name="category"
                id="category"
                class="form-select"
                v-model="formData.category"
                required
              >
                <option
                  v-for="category in categories.data"
                  :key="category.code"
                  :value="category.code"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2 mb-3">
            <div class="form-group">
              <label for="from-date">{{
                $t("saleParticipation.fromDate")
              }}</label>

              <input
                data-datepicker=""
                class="form-control datepicker-input"
                id="from-date"
                type="date"
                min="2021-01-01"
                placeholder="dd/mm/yyyy"
                required
                v-model="formData.fromDate"
              />
            </div>
          </div>
          <div class="col-md-2 mb-3">
            <div class="form-group">
              <label for="to-date">{{ $t("saleParticipation.toDate") }}</label>

              <input
                data-datepicker=""
                class="form-control datepicker-input"
                id="to-date"
                type="date"
                min="2021-01-01"
                placeholder="dd/mm/yyyy"
                required
                v-model="formData.toDate"
              />
            </div>
          </div>
          <div class="col-md-1 mb-3">
            <div class="form-group mt-4">
              <button
                class="btn btn-primary d-inline-flex align-items-center"
                type="button"
                @click="getReport(formData)"
                :disabled="!validInput"
              >
                <span class="fas fa-search mr-2"></span
                >{{ $t("saleParticipation.generate") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card card-body border-0 shadow mb-4" v-else>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap mb-0 rounded">
          <thead class="thead-light">
            <tr>
              <th class="border-0">{{ $t("saleParticipation.table.code") }}</th>
              <th class="border-0">
                {{ $t("saleParticipation.table.product") }}
              </th>
              <!-- <th class="border-0" @click="sortList('quantity')">{{ $t("saleParticipation.table.quantity") }} <i class="fas fa-sort"></i></th>
              <th class="border-0" @click="sortList('cost')">{{ $t("saleParticipation.table.total") }} <i class="fas fa-sort"></i></th> -->
              <th class="border-0" @click="sortList('percentage')">
                {{ $t("saleParticipation.table.participation") }}
                <i class="fas fa-sort"></i>
              </th>
            </tr>
          </thead>
          <tbody v-for="(value, propertyName) in sales" :key="propertyName">
            <tr class="thead-light">
              <th>{{ propertyName }}</th>
              <th></th>
              <th>
                <div class="medium fw-bold text-secondary">
                  {{ $t("saleParticipation.table.totalPercetnageForGroup") }}
                  {{ sumPercentageForVendor(value).toFixed(2) }}%
                </div>
              </th>
            </tr>
            <tr
              v-for="item in value"
              :key="item.productCode"
              :class="setColor(item.vendorCode)"
            >
              <td>{{ item.productCode }}</td>
              <td>{{ item.productName }}</td>
              <!-- <td>{{ item.quantity }}</td>
              <td>{{ item.cost | toCurrency }}</td> -->
              <td class="text-success">
                <div class="d-flex align-items-center">
                  <div class="col-12 col-xl-3 px-0">
                    <div class="small fw-bold">
                      {{ item.percentage.toFixed(2) }}%
                    </div>
                  </div>
                  <div class="col-12 col-xl-9 px-0 px-xl-1">
                    <div class="progress progress-lg mb-0">
                      <div
                        class="progress-bar bg-dark"
                        role="progressbar"
                        :aria-valuenow="item.percentage.toFixed(2)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: item.percentage.toFixed(2) + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locationService } from "../../services/location.service.js";
import { productService } from "../../services/product.service.js";
import { saleService } from "../../services/sale.service.js";
import store from "../../store/index.js";

export default {
  data() {
    return {
      reportName: this.$t("saleParticipation.reportName"),
      formData: {
        fromDate: new Date().toISOString().slice(0, 10),
        toDate: new Date().toISOString().slice(0, 10),
      },
      locations: [],
      categories: [],
      sales: [],
      sortedbyASC: false,
      loading: false,
      currentUser: {},
    };
  },
  computed: {
    validInput() {
      return (
        this.formData.fromDate !== undefined &&
        this.formData.toDate !== undefined &&
        this.formData.location !== undefined &&
        this.formData.category !== undefined
      );
    },
  },
  methods: {
    sortList(sortBy) {
      this.sortedbyASC = !this.sortedbyASC;
      Object.keys(this.sales).forEach((key) => {
        if (this.sortedbyASC) {
          this.sales[key] = this.sales[key].sort((x, y) =>
            x[sortBy] > y[sortBy] ? -1 : 1
          );
        } else {
          this.sales[key] = this.sales[key].sort((x, y) =>
            x[sortBy] < y[sortBy] ? -1 : 1
          );
        }
      });
    },
    setColor(rowVendor) {
      if (rowVendor === this.currentUser.vendorNo) {
        return "bg-secondary";
      }
      return "";
    },
    sumPercentageForVendor(groupItems) {
      // Filter the data based on the provided vendorCode
      const filteredData = groupItems.filter(
        (item) => item.vendorCode === this.currentUser.vendorNo
      );

      // Calculate the sum of percentage for the filtered data
      const sum = filteredData.reduce(
        (total, item) => total + item.percentage,
        0
      );

      return sum;
    },
    async getReport(data) {
      this.loading = true;
      let fromDate = new Date(data.fromDate);
      let toDate = new Date(data.toDate);

      let params = {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        location: data.location,
        productCategory: data.category,
      };

      let resp = await saleService.getSaleParticipation(params);

      this.sales = resp.data.reduce((groups, item) => {
        // Check if the current group already exists in the 'groups' object
        if (!groups[item.groupName]) {
          // If the group doesn't exist, create a new array with the current item
          groups[item.groupName] = [item];
        } else {
          // If the group exists, append the current item to its existing array
          groups[item.groupName].push(item);
        }
        return groups;
      }, {});

      this.loading = false;
    },
  },
  async created() {
    this.currentUser = store.getters.getUser;
    [this.categories, this.locations] = await Promise.all([
      productService.getAllCategories({ pageIndex: 0, pageSize: 100 }),
      locationService.getAllLocations(),
    ]);
    this.categories.data.unshift({ name: "---", code: null });
  },
};
</script>