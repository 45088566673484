import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

// styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/styles/firefly.css';

// ui components
import BaseModal from './components/UI/BaseModal.vue';
import DataTable from './components/UI/DataTable.vue';
import BaseWidget from './components/UI/BaseWidget.vue';
import DropdownList from './components/UI/DropdownList.vue';
import AreaChart from './components/Charts/AreaChart.vue';
import DateFilter from './helpers/date-filter'

// i18n configuration
import i18n from "./services/i18n";

Vue.config.productionTip = false;

Vue.component('base-modal', BaseModal);
Vue.component('data-table', DataTable);
Vue.component('base-widget', BaseWidget);
Vue.component('area-chart', AreaChart);
Vue.component('drop-down', DropdownList);

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('bs', {
      style: 'currency',
      currency: 'BAM'
  });
  return formatter.format(value);
});

Vue.filter('number', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('bs');
  return formatter.format(value);
});

Vue.filter('date', DateFilter);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
